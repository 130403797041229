<template>
  <Lottie
      style="transform: scaleX(-1);"
      :loop="false"
      :autoPlay="true"
      :options="{animationData: animData}"
  />
</template>

<script>
import Lottie from  'vue-lottie';

export default {
  name: 'JewelObject',
  components: {Lottie},
  props: {
    animData: {
      type: Object,
      require: true
    },
  },
  destroyed() {

  },
  mounted() {

  }
}
</script>