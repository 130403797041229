<template>
  <div class="game-sidebar">
    <div class="row">
      <Balance :balance="$attrs.state.balance" :currency="$attrs.state.currencyName" :numberLimit="$attrs.state.numberLimit"/>
    </div>
    <BetLayout :balance="$attrs.state.balance"
               :minBet="$attrs.state.minBet"
               :maxBet="$attrs.state.maxBet"
               :state="$attrs.state"
               :increaseRate="$attrs.state.increaseRate"
               :currency="$attrs.state.currencyName"
               :numberLimit="$attrs.state.numberLimit"
               :isDisabled="isDisabled"
    />


    <div class="row">
      <span class="action btn" @click="gameControl" v-bind:class="{'disabled': isDisabled }"
            v-html="btnText"/>
    </div>
  </div>
</template>

<script>


import Balance from "./Balance";
import BetLayout from "./BetLayout";

export default {
  name: 'Controller',
  components: {BetLayout, Balance},
  data() {
    return {
      betStep: 0.000001,
      numberLimit: 14
    }
  },
  mounted() {

  },
  methods: {

    selectOnBoard: function (){
      let random = Math.floor(Math.random() * 40);

      if (!this.$attrs.state.gameTable[random].selected) {
        this.$attrs.state.select(this.$attrs.state.gameTable[random]);
      } else {
        this.selectOnBoard();
      }
    },
    gameControl: async function () {
      let status = this.$attrs.state.status;
      if(this.isDisabled){
        return false;
      }


      if (status === 1 || status === 5) {

        this.$attrs.state.isWinner = false;
        this.$attrs.state.multiplier = 1.00;


        this.$attrs.state.resetGame();

        this.$attrs.state.balance -= this.$attrs.state.betAmount;


        let data = {
          "Token":window.mimikoClient.Token,
          "SiteId":Number(window.mimikoClient.SiteId),
          "CurrencyId": Number(window.mimikoClient.CurrencyId),
          "BetAmount": Number(this.$attrs.state.betAmount)
        };


        const rawResponseStart = await fetch(this.$attrs.state.endpoint, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });
        let responseStart = await rawResponseStart.json();
        if(responseStart.Error){
          this.$attrs.state.errorMsg = responseStart.Error;
          this.$attrs.state.isError = true;
        }

        let fColorType = -1;
        let sColorType = -1;

        let animCount = 0;
        if(responseStart.ColorArray){
          animCount = responseStart.ColorArray.length;
          let secondColorCount = 0;
          responseStart.ColorArray.map((i,index)=>{
            this.$attrs.state.setGameState(4);
            setTimeout(()=>{
              this.$attrs.state.gameTable[index].type = i;
              this.$attrs.state.gameTable[index].status = 1;
              animCount--;
              //FIXME:: burayada döngü ses efeği eklenecek
              this.$attrs.state.counts[i]++;
              let activeCount = this.$attrs.state.counts[i];

              // şükür hallettik
              this.$attrs.state.counts.map((c,index)=>{
                if(c>=2){
                  if(fColorType===-1) {
                    fColorType = index;
                  }else if(fColorType !== index){
                    secondColorCount = this.$attrs.state.counts[fColorType];
                    sColorType = index;
                  }else {
                    secondColorCount = c;
                  }
                }
              })



              //set colors
              this.$attrs.state.result.primary = this.$attrs.state.getColor(fColorType)
              this.$attrs.state.result.secondary = this.$attrs.state.getColor(sColorType)



              //set selection
              if(activeCount===5){
                this.$attrs.state.result.selection = 0;
              }else if(activeCount===4){
                this.$attrs.state.result.selection = 1;
              }else if(secondColorCount===3 && activeCount === 2){
                this.$attrs.state.result.selection = 2;
              }else if(activeCount===3 && secondColorCount === 2){
                this.$attrs.state.result.selection = 2;
              }else if(activeCount===2 && secondColorCount === 2){
                this.$attrs.state.result.selection = 4;
              }else if(activeCount===3 && secondColorCount ) {
                this.$attrs.state.result.selection = 3;
              }
              else if(activeCount===2 ){
                this.$attrs.state.result.selection = 5;
              }

              if(animCount===0){
                this.$attrs.state.setGameState(5);
                //FIXME:: buraya ses efeği filan gelecek
                if( responseStart.WinAmount>0) {
                  this.$attrs.state.multiplier = responseStart.Multiplier;
                  this.$attrs.state.winAmount = responseStart.WinAmount;
                  this.$attrs.state.isWinner = true;
                }

                this.$attrs.state.balance = responseStart.Balance;
              }
            },index*333)

          })

        }



      }

    }
  },
  computed: {
    isDisabled: function () {
      switch (this.$attrs.state.status) {
        case 1:
          return false
        case 5:
          return false
        default:
          return true;
      }
    },
    totalSelected: function (){
      var count = this.$attrs.state.gameTable.filter(val => {
        return val.selected
      })
      return count.length;
    },
    btnText: function () {
      switch (this.$attrs.state.status) {
        case 0:
          return 'Initializing';
        case 3:
          return 'Cashout';
        case 2: return 'Playing...';
        default:
          return 'Bet';
      }
    }
  },
  watch: {
    "$attrs.state.status": function (x) {
      if (x === 1) {
        this.$attrs.state.isWinner = false;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.next-multiplier {
  color: #15e703;
  font-size: 20px;
}

.next-multiplier>span {
  padding-bottom: 10px;
}
.game-sidebar {
  background: #213743;
}

.row select,
.row input {
  outline: none !important;
  font-size: 15px;
  padding: 5px;
  border-radius: 6px;
  background: rgb(0 0 0 / 20%);
  color: #fff;
  border: 3px solid rgb(255 255 255 / 22%);
  font-weight: 800;
  width: 100%;
}

.action,
.row input {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

span.btn {
  background: transparent;
  padding: 10px;
  cursor: pointer;
  font-family: 'Roboto';
  font-weight: bold;
}

.row {
  display: flex;
  padding: 5px 10px;
}

.row.btn {
  background: #15232c;
  margin: 5px 10px;
  padding: 5px;
  border-radius: 4px;
}

select.disabled,
.row.btn.disabled > *,
.action.btn.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.row > * {
  margin: 5px;
}

span.btn:hover {
  background: #fff;
  color: #000;
  border-radius: 4px;
}

span.btn sub {
  position: absolute;
  padding-top: 1px;
  padding-left: 2px;
}

.row.table > * {
  display: flex;
  width: 100%;
}

.row.table > *:last-child {
  text-align: right;
  justify-content: right;
}

.row.label {
  padding: 0 10px;
  margin-bottom: -10px;
}

.row select > option {
  background: #15232c;
}

.row select:hover,
.row input:hover {
  border: 3px solid #2196f3;
}

span.action.btn.secondary {
  background: #4d5a62;
  color: #fff;
  border-top: 1px solid hsl(0deg 0% 56% / 32%);
  border-bottom: 1px solid rgb(0 0 0 / 53%);
}

.action.btn {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  color: #000000;
  font-weight: 500;
  background: #00e701;
  box-shadow: 0 0 21px 5px rgb(0 0 0 / 25%);
  min-height: 40px;
  box-sizing: border-box;
}

.action.btn:hover {
  background: #2196f3;
}


</style>
