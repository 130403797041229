<template>
  <div :class="'jewel-animation  element_'+index">
    <div v-bind:class="{'showing':data.status === 1}">
      <JewelObject :animData="loadData(data.type)" v-if="data.type !== -1"/>

    </div>
  </div>
</template>


<script>
import {store} from "../Store";

import JewelObject from "./jewels/JewelObject";

export default {
  name: 'Jewel',
  components: {JewelObject},
  props: {
    data: {
      type: Object,
      require: true,
    },
    index: {
      type: Number,
      require: true,
    }
  },
  data() {
    return {
      active:false,
      animData: null
    }
  },


  methods:{
      loadData:function (type){
        return store.jewels[type].default
      }
  },

  watch: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@keyframes swingAnim {
  0% {
    transform: translate3d(0px, 0px, 0);
  }
  20% {
    transform: translate3d(-5px, 0px, 0);
  }
  40% {
    transform: translate3d(-0px, -5px, 0);
  }
  60% {
    transform: translate3d(-5px, 0px, 0);
  }
  80% {
    transform: translate3d(-5px, -5px, 0);
  }
  100% {
    transform: translate3d(0px, 0px, 0);
  }
}

.jewel-animation {
  position: relative;
  z-index: 9;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0px auto;
  min-height: 11vw;
  animation: swingAnim 3.3s ease-in-out infinite;

}

.jewel-animation > div {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  transform: translate3d(0px, -50px, 0);
}

.jewel-animation > div.showing {
  opacity: 1;
  transform: translate3d(0px, 0px, 0);
}


.jewel-animation.element_0 {
  animation-delay: 0.45s;
}

.jewel-animation.element_1 {
  animation-delay: 0.15s;
}

.jewel-animation.element_2 {
  animation-delay: 0s;
}

.jewel-animation.element_3 {
  animation-delay: 0.15s;
}

.jewel-animation.element_4 {
  animation-delay: 0.3s;
}

.jewel-animation.element_5 {
  animation-delay: 0.45s;
}

</style>