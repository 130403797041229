<template>
  <div class="pay-table">
    <StarBoard :layer="i" :result="result" v-for="(i,index) in paytable" :key="'sb_'+index" :index="index" />
  </div>
</template>

<script>
import StarBoard from "./StarBoard";
export default {
  name: 'Paytable',
  components: {StarBoard},
  props:{
    paytable:{
      type: Array[Object],
      require: true
    },
    result:{
      type: Object,
      require: true
    }
  },
  methods:{
    setStyle:function (paytable,loopIndex){
      let cl = 0;
      paytable.Match.map(i=>{
        cl+=i;
      })
      if(cl<loopIndex){
        return 'fill:#15232c';
      }else{
        return paytable.Match.map((i,index)=>{
          if(index===0 && index<loopIndex){
            return 'fill:#0F0;' //1.seçimi dolu olanlar
          }
          else if(index===0 && index+1<loopIndex){
            return  'fill:#FFF;'
          }
          else {
            return 'fill:#c90;stroke:#f00'
          }
        })
      }
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.diamond-display>* {
  margin: 0px 3px;
}

</style>