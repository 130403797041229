import {action, observable} from "mobx";

import * as j1 from './assets/jsons/7Jewels-01.json';
import * as j2 from './assets/jsons/7Jewels-02.json';
import * as j3 from './assets/jsons/7Jewels-03.json';
import * as j4 from './assets/jsons/7Jewels-04.json';
import * as j5 from './assets/jsons/7Jewels-05.json';
import * as j6 from './assets/jsons/7Jewels-06.json';
import * as j7 from './assets/jsons/7Jewels-07.json';

export default class Store {
    @observable endpoint = 'https://game-back.mimikogames.com/api/game/diamond';
    @observable gameTable = [];

    /**
     *  state.status
     *  0 init (bu kısımda oyun var mı hesabı yapılacak)
     *  1 ide
     *  2 loading
     *  4 oyun bitti
     *  5 animasyon tamam
     */
    @observable status = 0;

    @observable errorMsg = '';
    @observable isError = false;

    @observable betAmount = 0;
    @observable balance = 0;
    @observable multiplier = 1;
    @observable nextMultiplier = 1;
    @observable winAmount = 1;
    @observable isWinner = false;
    @observable jewels = [j1,j2,j3,j4,j5,j6,j7];
    @observable result = {
        selection:-1,
        primary:'#000',
        secondary:'#000'
    };


    @observable paytable = {};

    // :: currency işlemleri
    @observable currencies = [];
    @observable numberLimit = 2;
    @observable currencyName = '';
    @observable increaseRate = 0.000001;
    @observable maxBet = 0.1;
    @observable minBet = 0.000001;

    //animation temps

    @observable counts = [0,0,0,0,0,0,0]


    /**
     * 7 tip için 7 renk
     * @param type
     */
    @action.bound getColor(type) {
        if (type === 0) {
            return '#77b04a';
        } else if (type === 1) {
            return '#dc3f43';
        } else if (type === 2) {
            return '#fac020';
        } else if (type === 3) {
            return '#d060b6';
        } else if (type === 4) {
            return '#4884c2';
        } else if (type === 5) {
            return '#c77028';
        } else if (type === 6) {
            return '#87d9f3';
        }
        return '#000';
    }

    @action.bound gameTableUpdate(data) {
        this.gameTable = data;
    }

    @action.bound setGameState(state) {
        this.status = state;
    }

    @action.bound resetGame() {

        this.status = 1;
        this.counts = [0,0,0,0,0,0,0];
        this.result.selection = -1;
        this.result.primary = this.getColor(-1);
        this.result.secondary = this.getColor(-1);

        this.gameTable.map(i=>{
            i.status = 0;
            i.type = -1;
        })

    }

    @action.bound
    async getBalance() {
        let data = {
            "Token": window.mimikoClient.Token,
            "SiteId": Number(window.mimikoClient.SiteId),
            "CurrencyId": Number(window.mimikoClient.CurrencyId),
        };
        const rawResponseStart = await fetch('https://game-back.mimikogames.com/api/game/player/data', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const response = await rawResponseStart.json();
        this.balance = response.Balance;
    }

    @action.bound
    setCurrencyOperation() {

        this.currencies.map(i => {
            if (i.CurrencyId === Number(window.mimikoClient.CurrencyId)) {
                /**
                 * CurrencyId: 1000
                 CurrencyName: "BTC"
                 Decimal: 14
                 Id: 2
                 IncreaseRate: 0.000001
                 MaxBet: 0.1
                 MinBet: 0.000001
                 SiteId: 123
                 */

                this.currencyName = i.CurrencyName;
                this.numberLimit = i.Decimal;
                this.increaseRate = i.IncreaseRate;
                this.maxBet = i.MaxBet;
                this.minBet = i.MinBet;
                this.betAmount = i.MinBet;

            }
        })
    }


    @action.bound
    setBetAmount(p) {
        this.betAmount = Number(p);
    }


    @action.bound
    setJewel(index,type) {
        this.gameTable[index].data  = this.jewels[type];
    }


    @action.bound
    async getCurrencies() {


        let data = {
            "Token": window.mimikoClient.Token,
        };


        const rawResponseStart = await fetch('https://game-back.mimikogames.com/api/game/site/' + window.mimikoClient.SiteId + '/currencies', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const responseStart = await rawResponseStart.json();
        if (responseStart.Currencies) {
            this.currencies = responseStart.Currencies;
            this.setCurrencyOperation();
        }
    }


}

export const store = new Store();