<template>
  <div id="error" v-if="state.isError">
    {{ state.errorMsg }}
  </div>
  <div id="app" v-else>
    <Controller :state="this.state"/>
    <Game :state="this.state"/>
  </div>
</template>

<script>
import Game from './components/Game.vue'
import Controller from "@/components/Controller";
import {observer} from "mobx-vue";
import {store} from "./Store";

const queryString = require('query-string');
const externalData = queryString.parse(location.search);
window.mimikoClient = externalData;

export default observer({
  data() {
    return {
      state: store,
    }
  },
  mounted() {

    if (!externalData.SiteId) {
      this.state.isError = true;
      this.state.errorMsg = 'SiteId is undefined';
    }
    if (!externalData.CurrencyId) {
      this.state.isError = true;
      this.state.errorMsg = 'CurrencyId is undefined';
    }
    if (!externalData.Token) {
      this.state.isError = true;
      this.state.errorMsg = 'Token is undefined';
    }
    if(!this.state.isError){
      this.state.getCurrencies();
      this.state.getBalance();
    }
  },
  components: {
    Game,
    Controller
  }
})
</script>

<style>
body, html {
  padding: 0;
  margin: 0;
  background: #1a2c38;
  font-family: 'Roboto', sans-serif;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  margin-top: 60px;
}

div#app {
  max-width: 1200px;
  display: flex;
  margin: 10px auto;
  border-radius: 5px;
  overflow: hidden;
}

div#app > div:first-child {
  flex: 3;
}

div#app > div:last-child {
  flex: 10;
}

div#error {
  display: flex;
  font-size: 40pt;
  color: #f44336;
  padding: 20px;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100vh;
  box-sizing: border-box;
}

@keyframes tickTockBomb {
  0% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(-45deg);
  }
  75% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
img.mini-bomb {
  position: absolute;
  height: 27px;
  margin-top: -4px;
  animation: tickTockBomb 1s infinite;
}

@media screen and (max-width: 900px) {
  div#app {
    flex-wrap: nowrap;
    flex-direction: column-reverse;
  }
  .game-wrapper{
    padding: 5px 10px !important;
  }


}

@media screen and (max-width: 400px) {

}
</style>
