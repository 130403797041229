<template>
  <div class="game-zone">
    <div class="top-display">
      <Paytable :paytable="state.paytable" :result="state.result" />
      <div class="winner-display-container">
        <div class="winner-display" v-bind:class="{'active':state.isWinner}">
          <span class="multip">{{ state.multiplier | multiplier }}x</span>
          <span><span class="sperator"></span></span>
          <span class="winned-price">{{ formatPrice(state.winAmount) }}</span>
        </div>
      </div>
    </div>
    <div class="game-wrapper">
      <div class="card-container" v-for="(i,index) in state.gameTable" v-bind:key="index+'AAA'">

          <Jewel  :data="i" :index="index" />
          <div class="card-selection" v-bind:class="getClass(i)"></div>

      </div>


    </div>
  </div>
</template>

<script>


import Paytable from "./Paytable";
import Jewel from "./Jewel";

export default {
  name: 'Game',
  components: {Jewel, Paytable},
  async mounted() {
    if (!window.mimikoClient.Token) {
      return false;
    }
    let gameTable = [];
    for (let i = 0; i < 5; i++) {
      gameTable.push({
        status: 1,
        type: -1
      })
    }
    this.state = this.$attrs.state;

    let data = {
            "Token": window.mimikoClient.Token,
            "SiteId": Number(window.mimikoClient.SiteId),
            "CurrencyId": Number(window.mimikoClient.CurrencyId),
        }; 

    this.state.gameTableUpdate(gameTable);
    // get info
    const rawResponseStart = await fetch(this.state.endpoint + '/info', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const responseStart = await rawResponseStart.json();
    if (responseStart.Options) {
      this.state.paytable =  responseStart.Options.reverse();
    } else {
      this.state.errorMsg = 'Paytable can not loaded.';
      this.state.isError = true;
    }

    this.state.setGameState(1);
    /*
    let data = {
      Token: window.mimikoClient.Token,
      SiteId: Number(window.mimikoClient.SiteId),
      CurrencyId:   Number(window.mimikoClient.CurrencyId),
    }*/


  },
  filters: {
    multiplier: function (n) {
      return Number(n).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
  },
  computed: {
    lottie () {
      return this.$refs.lottie
    }
  },
  methods: {
    formatPrice: function (x) {
      return Number(x).toFixed(this.state.numberLimit);
    },
    getClass: function (i) {
      if(this.state.counts[i.type]>1){
        return 'type_'+i.type;
      }
      return ''
    }
  },
  watch:{
   /* 'state.gameTable':function (n,old){
      //console.log('changed',n,old);
    }*/
  },
  data() {
    return {
      /**
       *  state.status
       *  0 init (bu kısımda oyun var mı hesabı yapılacak)
       *  1 ide
       *  2 loading
       *  4 oyun bitti
       */
      state: {}
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.card-selection {
  height: 10px;
  background: #090c26;
  border-radius: 4px;
  opacity: 1;
  z-index: -1;
}

.top-display {
  display: flex;
  flex: 1;
}

.pay-table {
  flex: 1;
}

.winner-display-container {
  flex: 1;
  position: relative;
}


.card-selection.type_0 {
  background: #77b04a;
}
.card-selection.type_1 {
  background: #dc3f43;
}
.card-selection.type_2 {
  background: #fac020;
}
.card-selection.type_3 {
  background: #d060b6;
}
.card-selection.type_4 {
  background: #4884c2;
}
.card-selection.type_5 {
  background: #c77028;
}
.card-selection.type_6 {
  background: #87d9f3;
}

.game-zone {
  background: rgb(0 0 0 / 20%);
}
.winner-display,
.card-selection,
.selection,
img.mini-display,
.game-card {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}


.result-container.non-selection > img {
  opacity: 0.4;
  padding: 25px;
}


.card-container {
  display: inline-block;
  width: 17%;
  margin: 1%;
  position: relative;
}


.winner-display {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100px;
  width: 250px;
  background: #15232c;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  border: 4px solid #15e703;
}
.winner-display.active {
  opacity: 1;
}
.winner-display > * {
  display: flex;
  flex: 1;
}

span.winned-price {
  color: #15e703;
  font-size: 16px;
}

span.sperator {
  display: block;
  background: #2f4553;
  width: 25px;
  height: 3px;
}

span.multip {
  font-size: 26px;
  font-weight: 900;
}

.game-wrapper {
  background: rgb(26, 38, 106);
  background: radial-gradient(circle, rgba(26, 38, 106, 1) 0%, rgba(8, 11, 35, 1) 100%);
  margin: 15px;
  border-radius: 10px;
  padding: 25px 0;
  position: relative;
}

@media screen and (max-width: 1100px) {
  .game-wrapper {
    padding: 0;
  }

  div#app > div:last-child {
    flex: 11;
  }
}
@media screen and (max-width: 500px) {
  .top-display {
    flex-direction: column;
  }
  .winner-display{
    position: relative;
    top: inherit;
    bottom: inherit;
    left: inherit;
    right: inherit;
  }
}
</style>
