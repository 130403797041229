<template>
  <div class="pay-table-layer" v-on:mouseover="active = true" v-on:mouseleave="active= false" v-bind:class="{'active': checkActive}">
    <div class="diamond-display">
      <svg
          v-for="ii in 5"
          version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px" y="0px"
          :style="'stroke-width: 40px;'+getStyle(ii)"
          viewBox="0 0 491 491" style="enable-background:new 0 0 491 491;" xml:space="preserve" :key="'S_I'+index+'_'+ii">

                <g>
        <path class="st0" d="M436,192.8L386.4,82.2H104.6L55,192.8l190.5,235.4L436,192.8z"/>
      </g>
      </svg>
    </div>
    <div class="multiplier-display">
      {{ layer.Multiplier|multiplier }}<span>x</span>
    </div>
  </div>
</template>


<script>
export default {
  name: 'StarBoard',
  props: {
    index: {
      type: Number,
      require: true,
    },
    layer: {
      type: Object,
      require: true
    },
    result: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      active: false
    }
  },

  filters: {
    multiplier: function (n) {
      return n.toFixed(2);
    }
  },
  computed: {
    checkActive: function () {
      if (this.result.selection === this.index || this.active) {
        return true;
      }
      return false;
    }
  },
  mounted() {

  },
  methods: {
    getStyle: function (index) {

      let cl = 0;
      this.layer.Match.map(i => {
        cl += i;
      })
      if (cl < index) {
        return 'fill:#15232c';
      } else {
        if (index <= this.layer.Match[0]) {
          if (this.checkActive) {
            return 'fill:' + this.result.primary;
          }
          return 'fill:#213743'
        }
        if (this.checkActive) {
          return 'fill:#15232c;stroke:' + this.result.secondary;
        }
        return 'fill:#15232c;stroke:#213743'
      }
    }

  },
  watch: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.diamond-display {
  display: flex;
  flex: 1;
  width: 25px;
  height: 25px;
  padding: 0 15px;
}

svg#Capa_1 {
  display: flex;
}
.pay-table-layer.active {
  background: rgb(33 55 67);
}

.pay-table-layer {
  display: flex;
  background: rgb(26 44 56 / 45%);
  margin: 5px 15px;
  border-radius: 4px;
  padding: 4px;
}

.multiplier-display {
  display: flex;
  flex: 2;
  font-size: 14px;
  align-items: center;
  justify-content: end;
  padding: 0 15px;
}

</style>