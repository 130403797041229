<template>
  <div class="balance-layer">
    <div class="row" v-if="balance">
      {{balanceFormat(balance)}} <span>{{ currency }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Balance',
  props:{
    numberLimit:{
      type: Number,
      require: true
    },
    balance:{
      type: Number,
      require: true
    },
    currency:{
      type: String,
      require: true
    }
  },
  data() {
    return {
    }
  },
  filters:{
  },
  computed:{
  },
  mounted() {

  },
  methods: {
    balanceFormat: function (n) {
      return Number(n).toFixed(this.numberLimit).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.balance-layer {
  width: 100%;
  background: #15232c;
  padding: 10px;
  border-radius: 7px;
  color: #ffeb3b;
}
</style>